<script lang="ts" setup>
import {Ref} from "@vue/reactivity";
import {useConfirm} from "primevue/useconfirm";
import {useToast} from "primevue/usetoast";
import {useDialog} from "primevue/usedialog";
import AppLayoutTopbarMenu from "~/components/AppLayoutTopbarMenu.vue";
import AppSearchInput from "~/components/AppSearchInput.vue";

const confirm = useConfirm();
const toast = useToast();
const dialog = useDialog();

const emit = defineEmits<{
  (e: 'menuToggle', event: MouseEvent): void,
  (e: 'topbarMenuToggle', event: MouseEvent): void
}>();

function onTopbarMenuToggle(event: MouseEvent) {
  emit('menuToggle', event);
  emit('topbarMenuToggle', event);
}
</script>

<template>
  <div class="layout-topbar hidden">
    <div class="layout-topbar-left">
      <button class="p-link layout-menu-button layout-topbar-button" @click="onTopbarMenuToggle" v-if="false">
        <i class="pi pi-bars" />
      </button>

      <button
        v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true
      }"
        class="p-link layout-topbar-menu-button layout-topbar-button"
      >
        <i class="pi pi-ellipsis-v" />
      </button>
    </div>
    <div class=" h-full w-full ml-4 flex items-center justify-end">
      <AppSearchInput />
    </div>
    <AppLayoutTopbarMenu />
  </div>
</template>
