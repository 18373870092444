<script setup lang="ts">
import {useDataImport} from "~/store/data-import";
import {useRoute} from "#app";

const store = useDataImport();
const interval = ref(null);

onMounted(() => {
  store.fetchImportJobStates();

  interval.value = setInterval(() => {
    store.fetchImportJobStates();
  }, 2500);
});

onUnmounted(() => {
  clearInterval(interval.value);
});

const foo = ref(null);

const isSettingsArea = computed(() => {
  return useRoute().path.startsWith('/settings');
});
</script>

<template>
  <div class="fixed bottom-5 left-5 w-[220px]" style="z-index: 999;">
    <div v-for="job in store.getImportJobStates" :key="job" class="border border-gray-600 rounded-lg px-2 py-1.5" :class="{
      'bg-white text-black': !isSettingsArea,
      'text-white': isSettingsArea,
    }">
      <div v-if="job?.status === 'importing'" class="flex items-center gap-2 px-1">
        <i class="pi pi-spinner pi-spin" />
        {{ job.percentageProcessed }} % importiert
      </div>
      <div v-else-if="job?.status === 'completed'" class="flex items-center gap-2 px-1">
        <i class="pi pi-check-circle text-green-500"></i>
        Import abgeschlossen
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
