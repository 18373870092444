export const getCurrentEnvironment = () => {
  if (!import.meta.client) return null;
  const currentHost = window.location.hostname;

  if (currentHost.includes('app.fynn.eu')) {
    return 'production';
  } else if (currentHost.includes('localhost') || currentHost.includes('127.0.0.1')) {
    return 'development';
  } else if (currentHost.includes('staging.')) {
    return 'staging';
  } else if (currentHost.includes('preview.')) {
    return 'preview';
  } else {
    return null;
  }
}
