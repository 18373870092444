<script lang="ts" setup>
import AppSubmenu from './AppSubmenu.vue';

const props = defineProps({
model: {
    type: Array,
    default: () => ([])
  }
});
const emit = defineEmits(['menuitem-click']);

const onMenuItemClick = (event: any) => {
  emit('menuitem-click', event);
};
</script>

<template>
  <div class="layout-menu-container">
    <AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" />
  </div>
</template>

<style scoped>

</style>
