<script setup lang="ts">
import {Ref} from "@vue/reactivity";
import {computed, ComputedRef} from "vue";
import {getCurrentEnvironment} from "~/utils/environment";

const emit = defineEmits(['environmentChanged']);

const currentEnvironment: ComputedRef<'production' | 'development' | 'preview' | 'staging' | null> = computed(() => {
  const currentEnvironment = getCurrentEnvironment();

  if (!currentEnvironment || currentEnvironment === 'production') {
    return null;
  }

  return currentEnvironment;
});

const label = computed(() => {
  switch (currentEnvironment.value) {
    case 'development':
      return 'Entwicklungsumgebung';
    case 'staging':
      return 'Stagingumgebung';
    case 'preview':
      return 'Testumgebung';
  }
});

const color = computed(() => {
  switch (currentEnvironment.value) {
    case 'development':
      return 'bg-[#055E30] text-[#C1EFDF]';
    case 'staging':
      return 'bg-yellow-500 text-black';
    case 'preview':
      return 'bg-[#004F94] text-[#F1F4FF]';
  }
});
</script>

<template>
  <div class="fixed top-0 left-0 w-full h-1 z-[1000000] flex justify-center" :class="color" v-if="currentEnvironment">
    <div class="h-5 px-2 rounded-lg text-sm font-normal" :class="color">
      {{ label }}
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
